import styles from "./Contacts.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/auth/selectors";
import sprite from "../../../../icons.svg";
import { updateUserSettings } from "../../../../../redux/auth/operations";

export default function Contact() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const handeSubmit = (e) => {
    e.preventDefault();

    const formElements = e.target.elements;

    const phoneNumber = formElements.phoneNumber.value;
    const telegram = formElements.telegram.value;
    const instagram = formElements.instagram.value;
    const additionalInfo = formElements.additionalInfo.value;

    const formData = { phoneNumber, telegram, instagram, additionalInfo };

    console.log("Form was sended!");
    try {
      dispatch(updateUserSettings(formData));
      alert("Ваші дані були успішно оновленні!");
    } catch (error) {
      alert(
        "Сталася помилка під час оновлення даних, спробуйте, будь ласка, пізніше, або зверніться до підтримки!"
      );
      console.log(error);
    }
  };

  return (
    <>
      <h2 className={styles.titleSetting}>Ваші Контакти</h2>
      <form onSubmit={(e) => handeSubmit(e)} className={styles.FormUpdate}>
        <div className={styles.formGroupSetting}>
          <label htmlFor="phoneNumber" className={styles.SettingLabel}>
            <p className={styles.SettingPlaceholder}>Номер телефону</p>
            <input
              className={styles.InputSetting}
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              defaultValue={user.phoneNumber}
            />
          </label>
        </div>
        <div className={styles.formGroupSetting}>
          <label htmlFor="telegram" className={styles.SettingLabel}>
            <p className={styles.SettingPlaceholder}>Telegram</p>
            <input
              className={styles.InputSetting}
              type="text"
              id="telegram"
              name="telegram"
              defaultValue={user.telegram}
            />
          </label>
        </div>

        <div className={styles.formGroupSetting}>
          <label htmlFor="instagram" className={styles.SettingLabel}>
            <p className={styles.SettingPlaceholder}>Instagram</p>
            <input
              className={styles.InputSetting}
              type="instagram"
              name="instagram"
              defaultValue={user.instagram}
            />
          </label>
        </div>
        <div className={styles.formGroupSetting}>
          <label htmlFor="additionalInfo" className={styles.SettingLabel}>
            <p className={styles.SettingPlaceholder}>
              Альтернативний спосіб зв&apos;язку
            </p>
            <input
              className={styles.InputSetting}
              type="text"
              name="additionalInfo"
              id="additionalInfo"
              defaultValue={user.additionalInfo}
            />
          </label>
        </div>
        <div className={styles.wrapperSettingBtn}>
          <button className={styles.brownBtn}>
            <svg className={styles.checkMark} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-checkmark`}></use>
            </svg>
            ЗБЕРЕГТИ
          </button>
        </div>
      </form>
    </>
  );
}
