// import About from "./About/About";
import { useEffect } from "react";
import Donation from "./Donation/Donation";
import Footer from "./HFM/Footer";
import Header from "./HFM/Header";
import Hero from "./Hero/Hero";
import Questions from "./Questions/Questions";
import Reviews from "./Reviews/Reviews";
import Speakers from "./Speakers/Speakers";
import Tariff from "./Tariff/Tariff";
import Upcoming from "./UpComingConf/Upcoming";
import { useDispatch, useSelector } from "react-redux";
import { signInOrLoginWithGoogle } from "../../redux/auth/operations";
import { selectIsLoggedIn } from "../../redux/auth/selectors";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styles from './ConferenceSnow.module.css'

export default function MainPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // useNavigate вместо useHistory
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    // Извлекаем параметр code из URL
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");

    if (code && isLoggedIn === false) {
      // Если есть code в URL, завершаем процесс Google OAuth
      dispatch(signInOrLoginWithGoogle(code))
        .unwrap()
        .then(() => {
          // Убираем code из URL после успешного логина
          navigate("/conference", { replace: true }); // Навигация без history.replace
        })
        .catch((error) => {
          console.error("Error during Google OAuth:", error);
        });
    }
  }, [dispatch, isLoggedIn, navigate]);

  const [dots, setDots] = useState([]);

  useEffect(() => {
    const generateDots = () => {
      const tempDots = [];
      for (let i = 0; i < 275; i++) {
        tempDots.push({
          id: i,
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          animationDuration: `${Math.random() * 50 + 20}s`,
          animationDelay: `${Math.random() * -10}s`,
        });
      }
      setDots(tempDots);
    };
    generateDots();
  }, []);
    
  return (
    <>
      <div className={styles.bg}>
        {dots.map((dot) => (
          <div
            key={dot.id}
            className={styles.dotWrapper}
            style={{
              top: dot.top,
              left: dot.left,
              animationDuration: dot.animationDuration,
              animationDelay: dot.animationDelay,
            }}
          >
            <div className={styles.dot} />
          </div>
        ))}
      </div>
      <Header />
      <Hero />
      <Speakers />
      <Tariff />
      <Upcoming />
      <Reviews />
      <Questions />
      <Donation />
      <Footer />
    </>
  );
}
