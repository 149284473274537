import sprite from "../../icons.svg";
import styles from "./Notifications.module.css";

export default function Notifications() {
  return (
    <section className={styles.notificationsSection}>
      <div className="container">
        <h2 className={styles.titleNotification}>
          <svg className={styles.notificationSVG} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-notificationsProfile`}></use>
          </svg>
          Сповіщення
        </h2>
        <article>
          <ul className={styles.wrapperLogoTitle}>
            <li>
              <svg className={styles.mainLogo} width={40} height={40}>
                <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
              </svg>
            </li>
            <li>
              <h3 className={styles.maintitle}>Команда “ЖІНКИ”</h3>
            </li>
          </ul>
          <p className={styles.helloText}>
            Для вашої зручності ми пропонуємо налаштувати сповіщення важливих
            повідомлень від нас на telegram.
          </p>
        </article>
        <button className={styles.settinsNotificationBtn}>
          <a className={styles.tgLink} href="https://t.me/WomenPlace_bot">
            Перейти до telegram чат-бота
          </a>
        </button>
        <div id="renderArticleNotification"></div>
      </div>
    </section>
  );
}
