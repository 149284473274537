import styles from "./LoginParams.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/auth/selectors";
import sprite from "../../../../icons.svg";
import { updateUserSettings } from "../../../../../redux/auth/operations";
import axios from "axios";

export default function LoginParams() {
  const user = useSelector(selectUser);
  console.log(user);

  const dispatch = useDispatch();
  const handeSubmit = (e) => {
    e.preventDefault();

    const formElements = e.target.elements;

    const email = formElements.email.value;
    const password = formElements.password.value;
    axios.post("/reset-pwd", password);
    const formData = { email };

    console.log("Form was sended!");
    try {
      dispatch(updateUserSettings(formData));
      alert("Ваші дані були успішно оновленні!");
    } catch (error) {
      alert(
        "Сталася помилка під час оновлення даних, спробуйте, будь ласка, пізніше, або зверніться до підтримки!"
      );
      console.log(error);
    }
  };

  return (
    <>
      <h2 className={styles.titleSetting}>Параметри Входу</h2>
      <form onSubmit={(e) => handeSubmit(e)} className={styles.FormUpdate}>
        <div className={styles.formGroupSetting}>
          <label htmlFor="email" className={styles.SettingLabel}>
            <p className={styles.SettingPlaceholder}>Пошта</p>
            <input
              className={styles.InputSetting}
              type="email"
              id="email"
              name="email"
              defaultValue={user.email}
            />
          </label>
        </div>
        <div className={styles.formGroupSetting}>
          <label htmlFor="password" className={styles.SettingLabel}>
            <p className={styles.SettingPlaceholder}>Пароль</p>
            <input
              className={styles.InputSetting}
              type="text"
              id="password"
              name="password"
              defaultValue={user.password}
            />
          </label>
        </div>

        <div className={styles.wrapperSettingBtn}>
          <button className={styles.brownBtn}>
            <svg className={styles.checkMark} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-checkmark`}></use>
            </svg>
            ЗБЕРЕГТИ
          </button>
        </div>
      </form>
    </>
  );
}
