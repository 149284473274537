import styles from "./Settings.module.css";
import sprite from "../../icons.svg";
import { useState } from "react";

import Contacts from "./RenderingSettingBlock/Contacts/Contacts";
import LoginParams from "./RenderingSettingBlock/LoginParams/LoginParams";
import GeneralInfo from "./RenderingSettingBlock/GeneralInfo/GeneralInfo";

export default function Settings() {
  const [selectedSection, setSelectedSection] = useState("GeneralInfo");

  const renderSettingBlock = () => {
    switch (selectedSection) {
      case "Me":
        return <GeneralInfo />;
      case "Contact":
        return <Contacts />;
      case "Login":
        return <LoginParams />;
      default:
        return <GeneralInfo />;
    }
  };

  return (
    <section className={styles.settingsSection}>
      <div className="container">
        <h2 className={styles.titleSettings}>
          <svg className={styles.titleSettingsSVG} width={20} height={20}>
            <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
          </svg>
          Налаштування
        </h2>
        <ul className={styles.settingsList}>
          <li>
            <a
              href="#general-info"
              onClick={() => setSelectedSection("Me")} // Оборачиваем в функцию
            >
              <svg className={styles.MyVideoSVG} width={20} height={20}>
                <use xlinkHref={`${sprite}#icon-clientSVG`}></use>
              </svg>
              Загальна інформація
            </a>
          </li>
          <li>
            <a
              href="#contacts"
              onClick={() => setSelectedSection("Contact")} // Исправляем вызов
            >
              <svg className={styles.MyVideoSVG} width={20} height={20}>
                <use xlinkHref={`${sprite}#icon-phoneSVG`}></use>
              </svg>
              Контакти
            </a>
          </li>
          <li>
            {/* <a href="#login-params" onClick={() => setSelectedSection("Login")}> */}
            <a href="#login-params" className={styles.disabled}>
              <svg className={styles.MyVideoSVG} width={20} height={20}>
                <use xlinkHref={`${sprite}#icon-lockedSVG`}></use>
              </svg>
              Параметри входу
            </a>
          </li>
        </ul>
        <div className={styles.renderSettingBlock}>{renderSettingBlock()}</div>
      </div>
    </section>
  );
}
