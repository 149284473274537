import styles from "./PaymentRedirectPage.module.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import danceIMG from "./danceIMG.webp";

export default function PaymentRedirectPage() {
  useEffect(() => {
    // Вставляем код отслеживания события покупки здесь.
    if (window.fbq) {
      window.fbq("track", "Purchase", {
        currency: "EUR",
      });
    }
  }, []); // Пустой массив зависимостей гарантирует вызов только при первом монтировании

  return (
    <section className={styles.prpSection}>
      <div className="container">
        <h2 className={styles.titlePaymentPage}>Оплата успішна!</h2>
        <p className={styles.text}>
          Наші менеджери поки що танцюють від радості, що ви приєдналися до нас!
          🕺💃 Тому, якщо листи з деталями трохи затримаються — не хвилюйтеся,
          ми вже пакуємо для вас всю необхідну інформацію і скоро вона прилетить
          до вашої пошти.
        </p>
        <img
          className={styles.danceIMG}
          src={danceIMG}
          alt="dance"
          width={140}
          height={140}
        />
        <p className={styles.text}>
          Якщо питання горять швидше, ніж наші менеджери танцюють, — пишіть нам
          напряму, і ми допоможемо ще швидше! 🚀
        </p>
        <p className={styles.tgText}>
          Отримуйте всі необхідні сповіщення у телеграм!
        </p>
        <Link to="/conference">
          <button className={styles.backToConferencePageBtn}>
            Перейти до нашого чат-боту
          </button>
        </Link>
      </div>
    </section>
  );
}
