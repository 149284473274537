import styles from "./MyProfile.module.css";
import sprite from "../../icons.svg";
import { selectUser } from "../../../redux/auth/selectors";
import { useSelector } from "react-redux";

export default function MyProfile({ setSelectedSection }) {
  const user = useSelector(selectUser);
  const userName = user.firstName;
  const userSurname = user.lastName;

  return (
    <>
      <section className={styles.myProfileSection}>
        <div className="container">
          <div className={styles.wrapperMyInfoMain}>
            <svg className={styles.MyProfileLogo} width={60} height={60}>
              <use xlinkHref={`${sprite}#icon-user`}></use>
            </svg>
            <h2 className={styles.myName}>
              {userName} {userSurname}
            </h2>
            <button
              className={styles.myProfileSettingWrapper}
              onClick={() => setSelectedSection("Settings")}
            >
              <svg width={45} height={45}>
                <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
              </svg>
            </button>
            {/* <button
              className={styles.myProfileSettingWrapper}
              onClick={() => setSelectedSection("Settings")}
            >
              <svg
                width={45}
                height={45}
              >
                <use xlinkHref={`${sprite}#icon-settingsProfile`}></use>
              </svg>
            </button> */}
          </div>
          <article>
            <ul className={styles.wrapperLogoTitle}>
              <li>
                <svg className={styles.mainLogo} width={40} height={40}>
                  <use xlinkHref={`${sprite}#icon-womenPlace`}></use>
                </svg>
              </li>
              <li>
                <h3 className={styles.maintitle}>Команда “ЖІНКИ”</h3>
              </li>
            </ul>
            <p className={styles.helloText}>
              Привіт! Це головна сторінка твого профілю. Тут буде показано всю
              основну інформацію про тебе.
              <br /> <br />
              Ти можеш почати налаштовувати свій профіль. Вказувавати додаткову
              інформацію та особисті інтереси, на основі яких ми будемо
              підбирати для тебе найкращі матеріали.
            </p>
          </article>
          <button
            className={styles.settingsProfileBtn}
            onClick={() => setSelectedSection("Settings")}
          >
            Налаштувати профіль
          </button>
        </div>
      </section>
    </>
  );
}
