import styles from "./Support.module.css";
import sprite from "../../icons.svg";

export default function Support() {
  return (
    <section className={styles.supportSection}>
      <h2 className={styles.supportSectionTitle}>
        Наші контакти для зв&apos;язку
      </h2>
      <ul className={styles.contactLinksList}>
        <li>
          <a
            className={styles.contactLink}
            href="mailto:women@psyconference.org"
          >
            📧 women@psyconference.org
          </a>
        </li>
        <li>
          <a className={styles.contactLink} href="tel:+380936576711">
            📞 + 38 (093) 657 67 11
          </a>
        </li>
        <li>
          <a className={styles.contactLink} href="tel:+48667994337">
            📞 +48 667 994 337
          </a>
        </li>
        <li>
          <a
            className={styles.contactLink}
            alt="Telegram"
            aria-label="Перейти до Telegram"
            href="https://t.me/women_psyconference"
            target="_blank"
          >
            <svg className={styles.socialMediaIcon} width="36" height="36">
              <use xlinkHref={`${sprite}#icon-telegram`}></use>
            </svg>{" "}
            Telegram
          </a>
        </li>
        <li>
          <a
            className={styles.contactLink}
            alt="Instagram"
            aria-label="Перейти на Instagram"
            href="https://www.instagram.com/women.psyconference/"
            target="_blank"
          >
            <svg className={styles.socialMediaIcon} width="36" height="36">
              <use xlinkHref={`${sprite}icon-instagram`}></use>
            </svg>{" "}
            Instagram
          </a>
        </li>
      </ul>
    </section>
  );
}
