import styles from "./GeneralInfo.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/auth/selectors";
import sprite from "../../../../icons.svg";
import { updateUserSettings } from "../../../../../redux/auth/operations";

export default function GeneralInfo() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const handeSubmit = (e) => {
    e.preventDefault();

    const formElements = e.target.elements;

    const firstName = formElements.firstName.value;
    const lastName = formElements.lastName.value;
    const gender = formElements.gender.value;
    const country = formElements.country.value;
    const city = formElements.city.value;
    const formData = { firstName, lastName, gender, country, city };
    console.log("Form was sended!");
    try {
      dispatch(updateUserSettings(formData));
      alert("Ваші дані були успішно оновленні!");
    } catch (error) {
      alert(
        "Сталася помилка під час оновлення даних, спробуйте, будь ласка, пізніше, або зверніться до підтримки!"
      );
      console.log(error);
    }
  };

  return (
    <>
      <h2 className={styles.titleGeneralInfoSetting}>
        Налаштування загальної інформації
      </h2>
      <form
        className={styles.generalInfoFormUpdate}
        onSubmit={(e) => handeSubmit(e)}
      >
        <div className={styles.formGroupGeneralInfoSetting}>
          <label
            htmlFor="userNameMeSetting"
            className={styles.generalInfoSettingLabel}
          >
            <p className={styles.generalInfoSettingPlaceholder}>
              Імʼя (обовʼязково)
            </p>
            <input
              className={styles.InputGeneralInfoSetting}
              type="text"
              id="firstName"
              name="firstName"
              defaultValue={user.firstName}
            />
          </label>
        </div>
        <div className={styles.formGroupGeneralInfoSetting}>
          <label
            htmlFor="userNameMeSetting"
            className={styles.generalInfoSettingLabel}
          >
            <p className={styles.generalInfoSettingPlaceholder}>
              Прізвище (обовʼязково)
            </p>
            <input
              className={styles.InputGeneralInfoSetting}
              type="text"
              id="lastName"
              name="lastName"
              defaultValue={user.lastName}
            />
          </label>
        </div>
        <div className={styles.formGroupGeneralInfoSetting}>
          <p className={styles.generalInfoSettingPlaceholder}>Стать</p>
          <select
            className={styles.generalInfoSettingGender}
            id="gender"
            defaultValue={user.gender}
          >
            <option value="">Виберіть</option>
            <option value="female">Жінка</option>
            <option value="male">Чоловік</option>
            <option value="other">Інша</option>
          </select>
        </div>
        <div className={styles.formGroupGeneralInfoSetting}>
          <label htmlFor="country" className={styles.generalInfoSettingLabel}>
            <p className={styles.generalInfoSettingPlaceholder}>
              Країна проживання
            </p>
            <input
              className={styles.InputGeneralInfoSetting}
              type="text"
              name="country"
              id="country"
              defaultValue={user.country}
            />
          </label>
        </div>
        <div className={styles.formGroupGeneralInfoSetting}>
          <label htmlFor="city" className={styles.generalInfoSettingLabel}>
            <p className={styles.generalInfoSettingPlaceholder}>Місто</p>
            <input
              className={styles.InputGeneralInfoSetting}
              type="text"
              name="city"
              id="city"
              defaultValue={user.city}
            />
          </label>
        </div>
        <div className={styles.wrapperSettingGeneralInfoBtn}>
          <button className={styles.brownBtn}>
            <svg className={styles.checkMark} width={20} height={20}>
              <use xlinkHref={`${sprite}#icon-checkmark`}></use>
            </svg>
            ЗБЕРЕГТИ
          </button>
        </div>
      </form>
    </>
  );
}
