import style from "./Upcoming.module.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/effect-cards";
import "swiper/css";
import "swiper/css/scrollbar";
import { Scrollbar, Pagination, Navigation, EffectCards } from "swiper/modules";
import sprite from "../../icons.svg";
import { useState, useEffect } from "react";
import "swiper/css/pagination";
import { MoreQuestions } from "../HFM/Modals/Modules";
import { ChoiseTariffModal } from "../HFM/Modals/ChoiseTariffModal";
import { Link } from "react-router-dom";
import { selectIsLoggedIn } from "../../../redux/auth/selectors";
import { useSelector } from "react-redux";
import LogIn from "../../Modals/LogIn/LogIn";

export default function Upcoming() {
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [modalPrizeIsOpen, setModalPrizeOpen] = useState(false);
  const [modalMoreQuestionsIsOpen, setModalMoreQuestionsOpen] = useState(false); // Стан для модального вікна MoreQuestions
  const [modalLogInIsOpen, setModalLogIn] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 445) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(2);
      }
    }

    // Викликати handleResize при завантаженні сторінки
    handleResize();

    window.addEventListener("resize", handleResize);

    // Прибрати прослуховувач подій при розмонтовуванні компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const upcomingJson = [
    {
      titleCardF: "ПРО АГРЕСІЮ: жінок, ",
      titleCardS: "до жінок, біля жінок",
      dateCard: "30 червня - 1 липня 2024",
      btnCard: "Придбати записи",
      btnClass: "whiteBtn",
      link: "/aggression",
      classCard: style.wrapperInformationNextConferenceBefore,
    },
    {
      titleCardF: "флірт, кокетство, ",
      titleCardS: "любовна гра",
      dateCard: "10 - 11 вересня 2024",
      btnCard: "Придбати записи",
      btnClass: "whiteBtn",
      link: "/flirt",
      classCard: style.wrapperInformationNextConferenceBefore,
    },
    {
      titleCardF: "КОРДОНИ ТА МЕЖІ Заборони та їх порушення",
      titleCardS: "жінок, до жінок, біля жінок",
      dateCard: "27-28 жовтня",
      btnCard: "Придбати записи",
      btnClass: "whiteBtn",
      link: "/prohibition",
      classCard: style.wrapperInformationNextConferenceBefore,
    },
    {
      titleCardF: "Турбота та здатність турбуватись",

      dateCard: "24-25 лютого 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalPrizeOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: "Підглядання та показування",
      dateCard: "4 - 5 березня 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: "Переживання смутку та здатність засмучуватись",

      dateCard: "травень 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: " Вірність, відданість",
      titleCardS: "та єдність жінки та до жінок",
      dateCard: "липень 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: "Неперервність",
      dateCard: "вересень 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: "Тіло",
      dateCard: "листопад 2025",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: "Оргазм та оргастичні розрядки",
      dateCard: "січень 2026",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
    {
      titleCardF: "конференція - сюрприз",
      dateCard: "травень 2026",
      btnCard: "Відвідати конференцію",
      btnClass: "brownBtn",
      click: () => setModalMoreQuestionsOpen(true),
      classCard: style.wrapperInformationNextConference,
    },
  ];

  return (
    <>
      <section className={style.upcomingSection} id="upcomingSection">
        <div className="container">
          <h2 className={style.titleUpcoming}>
            /наші МАйбутні <br /> конференції
          </h2>
          <div
            className={`${style.mySwiperSpeakersNavigation} swiper-navigation`}
          >
            <div className={style.swiperButtonPrevUp}>
              <svg
                onClick={(e) => e.preventDefault()}
                className=""
                width={60}
                height={60}
                fill="white"
              >
                <use xlinkHref={`${sprite}#arrow-right`}></use>
              </svg>
            </div>
            <div className={style.swiperButtonNextUp}>
              <svg
                onClick={(e) => e.preventDefault()}
                className=""
                width={60}
                height={60}
                fill="white"
              >
                <use xlinkHref={`${sprite}#arrow-left`}></use>
              </svg>
            </div>
          </div>
          <Swiper
            slidesPerView={slidesPerView}
            initialSlide={2}
            spaceBetween={40}
            scrollbar={{
              draggable: true,
            }}
            pagination={{
              type: "fraction",
            }}
            navigation={{
              prevEl: `.${style.swiperButtonPrevUp}`,
              nextEl: `.${style.swiperButtonNextUp}`,
            }}
            modules={[Scrollbar, Pagination, Navigation]}
            className={style.mySwiperSpeakers}
          >
            {upcomingJson.map((coming, index) => (
              <SwiperSlide key={index} virtualIndex={index}>
                <div className={style.wrapperOneSlide}>
                  <article className={coming.classCard}>
                    <h3 className={style.titleInfoNextConference}>
                      {coming.titleCardF} <br /> {coming.titleCardS}
                    </h3>
                    <p className={style.textDateNextConference}>
                      {coming.dateCard} <br /> платформа зум
                    </p>
                    <Link to={coming.link}>
                      <button
                        className={coming.btnClass}
                        onClick={coming.click}
                      >
                        {coming.btnCard}
                      </button>
                    </Link>
                  </article>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className={style.abonementsSection} id="abonementsSection">
        <div className="container">
          <h2 className={style.titleAbonements}>/абонементи</h2>

          <p className={style.textundertitleAbonements}>
            Якщо ви хочете піти в глибоку роботу із собою — приєднайтеся до всіх
            11 циклів конференцій психологічного розвитку «ЖІНКИ»
          </p>
          <Swiper
            effect="cards"
            grabCursor={true}
            modules={[EffectCards]}
            className={`${style.cardsSwiper} ${style.onlyOnMobile}`}
            // onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            <SwiperSlide className={style.cardsSwiperSlide}>
              <h4 className={style.cardsSwiperSlideTitle}>22 дні</h4>
              <p className={style.cardsSwiperSlideText}>дослідження себе</p>
            </SwiperSlide>
            <SwiperSlide className={style.cardsSwiperSlide}>
              <h4 className={style.cardsSwiperSlideTitle}>150+ годин</h4>
              <p className={style.cardsSwiperSlideText}>
                які замінять роботу з кількома терапевтами
              </p>
            </SwiperSlide>

            <SwiperSlide className={style.cardsSwiperSlide}>
              <h4 className={style.cardsSwiperSlideTitle}>11 консультацій</h4>
              <p className={style.cardsSwiperSlideText}>
                зі спікерами після кожної конференції
              </p>
            </SwiperSlide>
          </Swiper>

          <div className={`${style.advantageTable} ${style.onlyOnDekstop}`}>
            <div className={style.tableRow}>
              <h4 className={style.tableRowTitle}>22 дні</h4>
              <p className={style.tableRowText}>дослідження себе</p>
            </div>
            <div className={style.tableRow}>
              <h4 className={style.tableRowTitle}>150+ годин</h4>
              <p className={style.tableRowText}>
                які замінять роботу <br /> з кількома терапевтами
              </p>
            </div>
            <div className={style.tableRow}>
              <h4 className={style.tableRowTitle}>11 консультацій</h4>
              <p className={style.tableRowText}>
                зі спікерами після <br /> кожної конференції
              </p>
            </div>
          </div>
          <ul className={style.wrapperAbonementsPlans}>
            <li>
              <p className={style.priceForAbonements}>999€</p>
              <p className={style.infoForAbonements}>
                <svg
                  className={style.spiralForPriceSVGAbon}
                  width={27}
                  height={27}
                >
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Онлайн участь на 11 конференціях
              </p>
              <p className={style.infoForAbonements}>
                <svg
                  className={style.spiralForPriceSVGAbon}
                  width={27}
                  height={27}
                >
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Відеозаписи всіх виступів <br /> з 11 конференції
              </p>
              <button
                className={`${style.redBtnAbonement} redBtn`}
                onClick={() => {
                  isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
                }}
              >
                Придбати
              </button>
            </li>
            <li>
              <p className={style.priceForAbonements}>1699€</p>
              <p className={style.infoForAbonements}>
                <svg
                  className={style.spiralForPriceSVGAbon}
                  width={27}
                  height={27}
                >
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Онлайн участь на 11 конференціях
              </p>
              <p className={style.infoForAbonements}>
                <svg
                  className={style.spiralForPriceSVGAbon}
                  width={27}
                  height={27}
                >
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                Відеозаписи всіх виступів <br /> з 11 конференції{" "}
              </p>
              <p className={style.infoForAbonements}>
                <svg
                  className={style.spiralForPriceSVGAbon}
                  width={27}
                  height={27}
                >
                  <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
                </svg>
                1 консультація на вибір одного зі спікерів після кожної
                конференції
              </p>
              <button
                className={`${style.redBtnAbonements} redBtn`}
                onClick={() => {
                  isLoggedIn ? setModalPrizeOpen(true) : setModalLogIn(true);
                }}
              >
                Придбати
              </button>
            </li>
          </ul>
        </div>
        <svg className={style.spiralDescAnimation} width={600} height={600}>
          <use xlinkHref={`${sprite}#icon-spiralSVG`}></use>
        </svg>
      </section>
      <ChoiseTariffModal
        isOpen={modalPrizeIsOpen}
        onClose={() => setModalPrizeOpen(false)}
      />
      <MoreQuestions
        isOpen={modalMoreQuestionsIsOpen}
        onClose={() => setModalMoreQuestionsOpen(false)}
      />
      <LogIn
        isOpen={modalLogInIsOpen}
        onClose={() => setModalLogIn(false)}
      ></LogIn>
    </>
  );
}
